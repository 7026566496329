(function () {
    const protocol = window.location.protocol;
    window.hxdomain = '';
    const hostMap = {
        localhost: {
            host: 'http://localhost:8081',
            googleClientId: '',
            privacy: 'http://localhost:8081/sea_privacy.html',
            agreement: 'http://localhost:8081/sea_agreement.html',
            help: 'http://localhost:8081/helpDocument',
            tranSatisfaction: 'http://localhost:8081/tranSatisfaction.html',
            membership: 'http://localhost:8081/membership.html',
        },
        'yytest.ths8.com': {
            host: 'http://yytest.ths8.com:8081',
            privacy:
                'http://yytest.ths8.com:8081/voiceclub_web/sea_privacy.html',
            agreement:
                'http://yytest.ths8.com:8081/voiceclub_web/sea_agreement.html',
            help: 'http://yytest.ths8.com:8081/voiceclub_web/helpDocument',
            tranSatisfaction:
                'http://yytest.ths8.com:8081/voiceclub_web/tranSatisfaction.html',
            membership:
                'http://yytest.ths8.com:8081/voiceclub_web/membership.html',
        },
        'www.voicecube.ai': {
            host: 'https://www.voicecube.ai',
            privacy: protocol + '//www.voicecube.ai/sea_privacy.html',
            agreement: protocol + '//www.voicecube.ai/sea_agreement.html',
            help: protocol + '//www.voicecube.ai/helpDocument',
            tranSatisfaction:
                protocol + '//www.voicecube.ai/tranSatisfaction.html',
            membership: protocol + '//www.voicecube.ai/membership.html',
        },
    };
    window.config = hostMap[location.hostname || 'localhost'];
})();

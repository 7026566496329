import axios from 'axios';
import { Message } from 'element-ui';
import store from '@/store/index';

// 服务端错误码
var ERROR_CODE = {
    10001: '您处于未登录状态',
    10002: '您缺少必要参数',
    10006: '该用户已注销',
    20001: '对不起，您的存储额度已不够使用',
    20002: '保存文件失败',
    20003: '文件未找到',
    20004: '文件上传失败', //"未准确读取到音频时长",
    30001: '记录未找到',
    40001: '转写失败',
    50001: '仅支持小于3小时的音频',
    50002: '转写超额，请明日再来',
    99999: '系统异常',
    2200001: '会员过期',
    2400014: 'The request is illegal',
};
axios.defaults.baseURL = window.config.host + '/sys-voiceclub-web';
axios.defaults.timeout = 600000;

axios.interceptors.request.use((config) => {
    config.params = { ...config.params, timeStamp: Date.now() };
    const token = localStorage.getItem('token');
    config.headers.common['token'] = token;
    config.headers.common['source'] = 0;
    return config;
});
axios.interceptors.response.use(
    (response) => {
        if (response.config.responseType === 'blob') {
            return Promise.resolve(response);
        }
        // axios返回数据中的data
        const dataAxios = response.data;
        let code = dataAxios.status_code;
        if (code !== 1) {
            if (code === 80000) {
                return Promise.reject(message);
            }
            let message =
                dataAxios.status_msg || ERROR_CODE[code] || 'System error';
            Message.closeAll();
            Message({
                type: 'error',
                message,
                customClass: 'detail-message',
            });
            /* ACCOUNT DELETE */
            if (code === 10006) {
                localStorage.removeItem('token');
                localStorage.removeItem('userId');
                if (window.location.host.indexOf('yytest') > -1) {
                    window.location.href =
                        'http://yytest.ths8.com:8081/voiceclub_web/';
                } else {
                    window.location.href = 'https://www.voicecube.ai/';
                }
            }
            /**
             * 会员过期
             */
            if (code === 2200001) {
                window.location.reload();
            }

            /* 无鉴权 */
            if (code === 2400014) {
                store.commit('loginShow', true);
            }

            return Promise.reject(message);
        }
        return Promise.resolve(dataAxios);
    },
    (error) => {
        if (error && error.response) {
            switch (error.response.status) {
                case 400:
                    error.message = '请求错误';
                    break;
                case 401:
                    error.message = '未授权，请登录';
                    break;
                case 403:
                    error.message = '拒绝访问';
                    break;
                case 404:
                    error.message = `请求地址出错: ${error.response.config.url}`;
                    break;
                case 408:
                    error.message = '请求超时';
                    break;
                case 500:
                    error.message = '服务器内部错误';
                    break;
                case 501:
                    error.message = '服务未实现';
                    break;
                case 502:
                    error.message = '网关错误';
                    break;
                case 503:
                    error.message = '服务不可用';
                    break;
                case 504:
                    error.message = '网关超时';
                    break;
                case 505:
                    error.message = 'HTTP版本不受支持';
                    break;
                default:
                    break;
            }
        }
        return Promise.reject(error);
    }
);

export const Get = (url, params, options) => {
    return axios.request({
        method: 'get',
        url,
        params,
        ...options,
    });
};

export const Post = (url, params) => {
    return axios.request({
        method: 'post',
        url,
        params,
    });
};

export const V2Post = (url, params) => {
    return axios.request({
        method: 'post',
        url,
        data: params,
    });
};

export const Put = (url, data) => {
    let form = new FormData();
    Object.keys(data).forEach((key) => {
        form.append(key, data[key]);
    });
    return axios.request({
        method: 'put',
        url,
        data: form,
    });
};

export const PostJson = (url, data) => {
    return axios.request({
        method: 'post',
        url,
        data,
    });
};

export const PostForm = (url, data) => {
    let form = new FormData();
    Object.keys(data).forEach((key) => {
        if (key === 'files') {
            data[key].forEach((file) => {
                form.append('files', file);
            });
        } else {
            form.append(key, data[key]);
        }
    });
    return axios.request({
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        method: 'post',
        url,
        data: form,
    });
};

export const PostWWW = (url, data) =>
    axios.request({
        url,
        method: 'post',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        data: new URLSearchParams(data),
    });

export const Delete = (url, params) =>
    axios.request({
        url,
        method: 'delete',
        params,
    });

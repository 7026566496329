<template>
    <div class="google-signin">
        <div
            id="g_id_onload"
            data-context="signin"
            data-ux_mode="popup"
            data-auto_prompt="false"
            :data-client_id="clientId"
            data-callback="OnGoogleAuthSuccess"
        ></div>

        <div
            class="g_id_signin"
            data-type="standard"
            data-shape="rectangular"
            data-theme="outline"
            data-text="signin_with"
            data-size="large"
            data-locale="en-US"
            data-logo_alignment="center"
            data-width="400"
        ></div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            clientId: process.env.VUE_APP_GOOGLE_ID,
        };
    },
    mounted() {
        this.initGoogleAuth();
    },
    methods: {
        async initGoogleAuth() {
            // 使用动态脚本--测试环境yytest无法使用代理获取脚本，因此在非代理情况下
            // 打开网站，代理情况下执行该脚本
            const script = document.createElement('script');
            script.src = 'https://accounts.google.com/gsi/client';
            script.async = true;
            const that = this;
            script.onload = function () {
                that.$emit('loaded');
            };
            document.head.appendChild(script);
        },
    },
};
</script>

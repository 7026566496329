import { render, staticRenderFns } from "./user-identify-dialog.vue?vue&type=template&id=5426de10&scoped=true"
import script from "./user-identify-dialog.vue?vue&type=script&lang=js"
export * from "./user-identify-dialog.vue?vue&type=script&lang=js"
import style0 from "./user-identify-dialog.vue?vue&type=style&index=0&id=5426de10&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5426de10",
  null
  
)

export default component.exports
import { Get, Put, Post } from '../utils/HttpUtils';

export const getUserInfo = () => Get('/api/v1/user/info');

export const updateUserInfo = (data) => Put('/api/v1/user/info', data);

export const checkUser = () => Get('api/v3/user/virtual_login');

export const getOrder = (params) => Get('order/get_transfer_order', params);

export const getCardList = () => Get('api/v3/user/get_user_duration_card_list');

export const getCardDetail = (params) =>
    Get('api/v3/user/get_duration_detail', params);

/* 获取用户注销状态 */
export const fetchAccountStatus = () =>
    Post('api/v3/user/delete_account_status');

/**
 * 获取职业信息和渠道列表
 */
export const fetchIdentifyAndSource = () => Get('api/v1/info_collect');

/**
 * 提交职业信息和渠道
 */
export const updateIdentifyAndSource = (data) =>
    Post('api/v1/update_info_collect', data);

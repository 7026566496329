import { googleSignIn } from '@/api/login-api';

(function () {
    // google登录 回调不支持命名空间，需要全局声明函数
    window.OnGoogleAuthSuccess = async function (response) {
        try {
            const res = await googleSignIn({ idToken: response.credential });
            const { userId, token } = res.data;
            localStorage.setItem('userId', userId);
            localStorage.setItem('token', token);
            window.location.reload();
        } catch (error) {
            console.log('google登录异常', error);
        }
    };
})();

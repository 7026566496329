import './utils/config';
import './utils/google-signin';
import Vue from 'vue';
import App from './App';
import router from './router';
import store from './store';
import uploader from 'vue-simple-uploader';
import InfiniteScroll from 'vue-infinite-scroll';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/eleReset.css';
import './assets/common.css';
import VueScroll from 'vuescroll';
import filters from './utils/filters';
import mavonEditor from 'mavon-editor';
import 'mavon-editor/dist/css/index.css';
import SpkInfo from './components/SpkInfo';
import i18n from './i18n';

import {
    Row,
    Col,
    Pagination,
    Dialog,
    Input,
    Image,
    Badge,
    Dropdown,
    DropdownMenu,
    DropdownItem,
    Header,
    Aside,
    Button,
    Progress,
    Popover,
    Main,
    Container,
    Table,
    TableColumn,
    Select,
    Option,
    Loading,
    Tooltip,
    Switch,
    MessageBox,
    Message,
    Alert,
    Backtop,
    Form,
    FormItem,
    Notification,
    ButtonGroup,
    Menu,
    Submenu,
    MenuItemGroup,
    MenuItem,
    Tag,
    Divider,
    Link,
    Rate,
    Slider,
    Breadcrumb,
    BreadcrumbItem,
    Upload,
    Cascader,
    RadioGroup,
    Radio,
    CheckboxGroup,
    Checkbox,
    TabPane,
    Tabs,
    RadioButton,
    Steps,
    Step,
    InputNumber,
    Popconfirm,
    Carousel,
    CarouselItem,
} from 'element-ui';

Vue.use(SpkInfo);
Vue.use(Row);
Vue.use(Col);
Vue.use(Pagination);
Vue.use(Dialog);
Vue.use(Input);
Vue.use(Image);
Vue.use(Badge);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Header);
Vue.use(Aside);
Vue.use(Button);
Vue.use(Progress);
Vue.use(Popover);
Vue.use(Main);
Vue.use(Container);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Select);
Vue.use(Option);
Vue.use(Tooltip);
Vue.use(Switch);
Vue.use(Alert);
Vue.use(Backtop);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(ButtonGroup);
Vue.use(Menu);
Vue.use(Submenu);
Vue.use(MenuItemGroup);
Vue.use(MenuItem);
Vue.use(uploader);
Vue.use(InfiniteScroll);
Vue.use(Tag);
Vue.use(Divider);
Vue.use(Link);
Vue.use(Rate);
Vue.use(Slider);
Vue.use(Breadcrumb);
Vue.use(BreadcrumbItem);
Vue.use(Upload);
Vue.use(Cascader);
Vue.use(RadioGroup);
Vue.use(Radio);
Vue.use(CheckboxGroup);
Vue.use(Checkbox);
Vue.use(VueScroll, {
    ops: {
        bar: {
            background: '#d8d8d8',
            keepShow: true,
        },
    },
    name: 'VueScroll',
});
Vue.use(Loading.directive);
Vue.use(TabPane);
Vue.use(Tabs);
Vue.use(RadioButton);
Vue.use(Steps);
Vue.use(Step);
Vue.use(InputNumber);
Vue.use(Popconfirm);
Vue.use(Carousel);
Vue.use(CarouselItem);

Vue.use(mavonEditor);

Vue.prototype.$loading = Loading.service;
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$notify = Notification;
Vue.prototype.$message = Message;

function _msg(message, type = 'success') {
    Message.closeAll();
    Message({
        message,
        type,
        customClass: 'detail-message',
    });
}
Vue.prototype.$msg = _msg;

/* 注册过滤器 */
for (let key in filters) {
    Vue.filter(key, filters[key]);
}

new Vue({
    el: '#app',
    router,
    store,
    i18n,
    components: { App },
    render: (h) => h(App),
    directives: { InfiniteScroll },
}).$mount('#app');

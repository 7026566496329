<template>
    <el-dialog
        custom-class="user-identify-dialog"
        :visible="visible"
        :close-on-click-modal="false"
        :before-close="beforeClose"
        @open="getIdentifyAndSource"
    >
        <div v-if="active === 1" class="step step-1">
            <div class="title">Choose areas related to you</div>
            <div class="sub-title">
                Offer you more accurate transcription models and services
            </div>
            <div class="identify-list">
                <div
                    v-for="(item, index) in identifyList"
                    :key="index"
                    :class="[
                        'identify-box',
                        selectedIdentify === item && 'active',
                    ]"
                    @click="selectIdentify(item)"
                >
                    {{ item }}
                </div>
            </div>
            <el-button
                class="next-step"
                type="primary"
                :loading="nexting"
                :disabled="selectedIdentify === undefined"
                @click="handleNext"
                >Next(1/2)</el-button
            >
        </div>
        <div v-else class="step step-2">
            <div class="title">How did you find us?</div>
            <div class="source-list">
                <div
                    v-for="(item, index) in sourceList"
                    :key="index"
                    :class="['source-box', selectedSource === item && 'active']"
                    @click="selectSource(item)"
                >
                    {{ item }}
                </div>
            </div>
            <div class="footer">
                <el-button
                    v-if="step !== 2"
                    class="prev-step"
                    @click="handlePrev"
                    >Previous</el-button
                >
                <el-button
                    class="submit-btn"
                    type="primary"
                    :loading="closing"
                    :disabled="selectedSource === undefined"
                    @click="handleSubmitSource"
                    >Enter(2/2)</el-button
                >
            </div>
        </div>
    </el-dialog>
</template>

<script>
import {
    fetchIdentifyAndSource,
    updateIdentifyAndSource,
} from '@/api/user-api';

export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        step: {
            type: Number,
            default: 1,
        },
    },
    data() {
        return {
            active: 1,
            nexting: false,
            closing: false,
            identifyList: [],
            sourceList: [],
            selectedIdentify: undefined,
            selectedSource: undefined,
        };
    },
    methods: {
        /**
         * 获取身份及来源
         */
        async getIdentifyAndSource() {
            try {
                const res = await fetchIdentifyAndSource();
                this.identifyList = res.data && res.data[0] ? res.data[0] : [];
                this.sourceList = res.data && res.data[1] ? res.data[1] : [];
            } catch (error) {
                console.log('获取用户身份和来源失败', error);
            }
        },
        /**
         * 关闭弹窗
         */
        beforeClose() {
            this.$emit('close');
            // 在用户信息页则入参均为Skip
            // 在渠道页则source为Skip
            if (this.active === 1) {
                updateIdentifyAndSource({
                    identity: 'Skip',
                    channel: 'Skip',
                });
            } else {
                updateIdentifyAndSource({
                    channel: 'Skip',
                });
            }
        },
        /**
         * 选择身份
         * @param index 身份索引
         */
        selectIdentify(index) {
            this.selectedIdentify = index;
        },
        /**
         * 点击下一步
         */
        async handleNext() {
            try {
                this.nexting = true;
                await updateIdentifyAndSource({
                    identity: this.selectedIdentify,
                });
                this.active = 2;
            } catch (error) {
                console.log('提交用户信息错误', error);
            } finally {
                this.nexting = false;
            }
        },
        /**
         * 选择用户来源
         * @param index 用户来源索引
         */
        selectSource(index) {
            this.selectedSource = index;
        },
        /**
         * 点击上一步
         */
        handlePrev() {
            this.active = 1;
        },
        /**
         * 提交来源
         */
        async handleSubmitSource() {
            // TODO 提交请求
            try {
                this.closing = true;
                await updateIdentifyAndSource({
                    channel: this.selectedSource,
                });
                this.$emit('close');
            } catch (error) {
                console.log('提交用户信息错误', error);
            } finally {
                this.closing = false;
            }
        },
    },
    watch: {
        step(val) {
            this.active = val;
        },
    },
};
</script>

<style scoped lang="less">
/deep/ .user-identify-dialog {
    width: 462px;
    height: 518px;
    border-radius: 12px;
    .el-dialog__header {
        padding: 0;
    }

    .el-dialog__body {
        height: 100%;
    }

    .step {
        position: relative;
        height: 100%;
    }

    .title {
        font-weight: 600;
        font-size: 20px;
        color: #1d2129;
    }

    .sub-title {
        margin-top: 8px;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.5);
    }

    .identify-list {
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        margin-top: 24px;
        height: 300px;
        overflow: auto;
        .identify-box {
            margin: 0 6px 12px;
            width: calc(100% / 3 - 12px);
            height: 40px;
            line-height: 40px;
            background: #f7f9fa;
            border-radius: 6px;
            color: #000000;
            cursor: pointer;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .identify-box:hover {
            color: #1876ff;
        }

        .identify-box.active {
            background: rgba(24, 118, 255, 0.1);
            color: #1876ff;
        }
    }

    .next-step {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
    }

    .source-list {
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        margin-top: 24px;
        height: 300px;
        overflow: auto;
        .source-box {
            margin: 0 6px 12px;
            width: calc(100% / 2 - 12px);
            height: 40px;
            line-height: 40px;
            background: #f7f9fa;
            border-radius: 6px;
            color: #000000;
            cursor: pointer;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .source-box:hover {
            color: #1876ff;
        }

        .source-box.active {
            background: rgba(24, 118, 255, 0.1);
            color: #1876ff;
        }
    }

    .footer {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
    }
}
</style>
